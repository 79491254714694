/** @jsxImportSource @emotion/react */
import {css, jsx, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";

// styles

const HeaderContainer = styled('div')`
  position: absolute;
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
`;

const HeaderTop = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTopInner = styled('div')`
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 770px) {
    flex-direction: row-reverse;
  }
`;

const LogoInHeader = styled('img')`
  height: 60px;
`;

const LinksContainer = styled('div')`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 770px) {
    display: none;
  }


  & a {
    color: white;
    font-size: 14pt;

    @media screen and (max-width: 770px) {
      font-size: 12pt;
    }
  }
`;


function Header(props) {
	const theme = useTheme();

	const location = useLocation();
	const navigation = useNavigate();

	const [mobileMenu, setMobileMenu] = useState(false);

	const MobileMenu = styled(motion.div)`
      display: flex;
      position: absolute;
      background: url("/pattern.png") no-repeat center;
      background-size: cover;
      width: 220px;
      flex-direction: column;
      justify-content: center;
      left: 0;
      top: 0;
      gap: 20px;
      height: 100vh;
      padding-left: 30px;
	  z-index: 999;

      & * {
        color: white;
        font-size: 14pt;
      }
	`;

	const container = {
		hidden: {x: -250},
		show: {
			x: 0,
		},
	};

	return (
		<div>
			<HeaderContainer>
				<HeaderTop>
					<HeaderTopInner className={"container"}>

						<NavLink to={'/'}>
							<LogoInHeader src={"/icon.svg"}/>
						</NavLink>

						<LinksContainer>
							<NavLink to={'/'}>
								Home
							</NavLink>

							<NavLink to={'about'}>
								About Us
							</NavLink>

							<a href={'#services'} onClick={() => {
								if (!(location.pathname === '/')) {
									navigation("/#services")
								}
							}
							}>
								Services
							</a>

							<NavLink to={'projects'}>
								Projects
							</NavLink>

							<NavLink to={'contact'}>
								Contact Us
							</NavLink>
						</LinksContainer>

						<FontAwesomeIcon icon={faBars}
										 onClick={() => {
											 setMobileMenu(!mobileMenu);
										 }}
										 css={css`
                                           display: none;
                                           color: white;
                                           font-size: 18pt;
                                           cursor: pointer;
                                           @media screen and (max-width: 770px) {
                                             display: block;
                                           }
										 `}/>

						<MobileMenu
							variants={container}
							initial="hidden"
							animate={mobileMenu ? "show" : "hidden"}
						>
							<NavLink to={'/'} onClick={() => {
							setMobileMenu(false)
							}
							}>
								Home
							</NavLink>

							<NavLink to={'about'} onClick={() => {
								setMobileMenu(false)
							}
							}>
								About Us
							</NavLink>

							<NavLink to={'services'} onClick={() => {
								setMobileMenu(false)
							}
							}>
								Services
							</NavLink>

							<NavLink to={'projects'} onClick={() => {
								setMobileMenu(false)
							}
							}>
								Projects
							</NavLink>

							<NavLink to={'contact'} onClick={() => {
								setMobileMenu(false)
							}
							}>
								Contact Us
							</NavLink>

							<FontAwesomeIcon icon={faXmark}
											 css={css`
                                               position: absolute;
                                               top: 20px;
                                               right: 20px;
                                               font-size: 18pt;
                                               cursor: pointer;
											 `}
											 onClick={() => {
												 setMobileMenu(false);
											 }}
							/>

						</MobileMenu>

					</HeaderTopInner>
				</HeaderTop>
			</HeaderContainer>
		</div>
	);
}

export default Header;
