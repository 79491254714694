import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@emotion/react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";

const Home = React.lazy(() =>
	import('./views/Home'),
);

const Contact = React.lazy(() =>
	import('./views/Contact'),
);

const About = React.lazy(() =>
	import('./views/About'),
);

const Projects = React.lazy(() =>
	import('./views/Projects'),
);

const Services = React.lazy(() =>
	import('./views/Services'),
);

const theme = {
	colors: {
		theme1: "#65AE61",
		theme2: "#5AAE7E",
		theme3: "#7EB842",
		theme4: "#3EA886",
		theme5: "#23A39A",

		whiteBackground: "#fefefe",
		mainTextColor: "#000",
	},
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Suspense fallback={<div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh", position: "absolute", left: 0, top: 0}}><img src={"icon.png"} style={{width: 150}} alt={"company icon"} /></div>}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<App/>}>
							<Route index element={<Home/>}/>
							<Route path="/projects" element={<Projects/>}/>
							<Route path="/services" element={<Services/>}/>
							<Route path="/contact" element={<Contact/>}/>
							<Route path="/about" element={<About/>}/>
						</Route>
					</Routes>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
