import Header from "./components/Header";
import {Outlet, useLocation} from "react-router-dom";
import {Global} from "@emotion/react";
import Footer from "./components/Footer";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import React, {useEffect} from "react";


function App() {

	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		document.getElementById("loading").style.display = "none";
	}, []);

	return (
		<>

			<Global
				styles={{

					'.locations-container': {
						marginTop: 50,
						display: "grid",
						boxSizing: "border-box",
						gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
						alignItems: "start",
						gap: 30,

						"& > div": {
							height: "100%",
						},
					},
				}}
			/>

			<Header/>

			<Outlet/>

			<Footer />

			<NotificationContainer/>

		</>
	);
}

export default App;
